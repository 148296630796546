.myButton,
.myLink {
  background-color: #001529;
  border: 1px solid #001529;
  margin: 5px;
  color: white;
}

.myButton:hover,
.myButton:focus,
.myLink:hover,
.myLink:focus {
  color: white;
  border-color: #001529;
  background: #001529;
}

.alreadyEntered {
  background-color: rgba(130, 189, 124, 0.26);
}

.debtsTree .ant-tree-indent,
.ordersTable .ant-tree-indent {
  display: none;
}

.ant-table-column-sorter {
  display: none;
}

/* Стилі для інформаційного вікна над маркером */
button.gm-ui-hover-effect {
  visibility: hidden;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track,
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-tc::after,
.gm-style .gm-style-iw-t::after {
  /* color: white; */
  /* border-color: #001529; */
  /* background: #001529; */
}

body {
  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.changeQtyInput .ant-input-number-group-addon {
  padding: 0px !important;
}

#rc-tabs-3-tab-3 span {
  margin: 0px;
}

.modalEnterProduct textarea:focus,
.modalEnterProduct textarea.form-control:focus,
.modalEnterProduct input.form-control:focus,
.modalEnterProduct input[type="text"]:focus,
.modalEnterProduct input[type="password"]:focus,
.modalEnterProduct input[type="email"]:focus,
.modalEnterProduct input[type="number"]:focus,
.modalEnterProduct [type="text"].form-control:focus,
.modalEnterProduct [type="password"].form-control:focus,
.modalEnterProduct [type="email"].form-control:focus,
.modalEnterProduct [type="tel"].form-control:focus,
.modalEnterProduct [contenteditable].form-control:focus,
.inputData:focus {
  box-shadow: inset 0 -1px 0 #ddd !important;
  border: 1px solid #202124 !important;
}

:where(.css-dev-only-do-not-override-12eb7gl).ant-table-wrapper
  .ant-table.ant-table-small
  .ant-table-tbody
  .ant-table-wrapper:only-child
  .ant-table {
  margin-left: 0px !important;
}

.ant-table-title {
  padding: 5px !important;
}

.ant-tabs-tab .ant-tabs-tab-btn span {
  margin-right: 5px !important;
}

/* .enteredQty {
  background-color: rgba(130, 189, 124, 0.26);
} */

.adm-number-keyboard-key {
  color: black;
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none;
}

.ant-picker-panel-container,
.ant-picker-footer {
  width: 280px !important;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}
