.loginPage {
  align-content: flex-start;
  align-items: center;
  background: url("../../public/background-main-mobile-min.jpg");
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}
.loginForm {
  background-color: rgba(14, 14, 24, 0.6);
  border: 1px solid #fff;
  border-radius: 16px;
  margin-top: 5%;
  padding: 15px 25px;
  text-align: center;
}
.loginForm label {
  color: white !important;
}
